<template>
    <div class="container">
        <BreadcrumbNav :pageName="$t('project.publish')"></BreadcrumbNav>
        <div class="add_box auto">
            <!--   one   -->
            <div class="Add_nav ">
                <div class="fl nav_item nav_active_a cur">{{$t('home.findTalents')}}</div>
                <div class="fl nav_item cur">{{$t('home.findMoney')}}</div>
                <div class="fl nav_item cur">{{$t('home.findWisdom')}}</div>
                <div class="clearfix"></div>
            </div>

            <!--   two   -->
            <div class="upload_box">
                <div class="upload_one">
                    <ul>
                        <li class="fl upload_active cur">
                            <div class="fl upload_hint_img">
                                <img alt="" src="../../assets/images/icon_uplx1@2x.png" width="36px">
                            </div>
                            <div class="fl upload_hint" style="padding-top: 24px">
                                <h2>One high resolution
                                    mage</h2>
                                <p>PNG,JPG,GIF+
                                    Cropping</p>
                            </div>
                            <span class="line"></span>
                        </li>
                        <li class="fl cur">
                            <div class="fl upload_hint_img">
                                <img alt="" src="../../assets/images/icon_uplx2@2x.png" width="36px">
                            </div>
                            <div class="fl upload_hint" style="padding-top: 42px">
                                <h2>Animated GIF</h2>
                                <p>376x250,695x460</p>
                            </div>
                            <span class="line"></span>
                        </li>
                        <li class="fl cur">
                            <div class="fl upload_hint_img">
                                <img alt="" src="../../assets/images/icon_uplx3@2x.png" width="36px">
                            </div>
                            <div class="fl upload_hint" style="padding-top: 42px">
                                <h2>Videos</h2>
                                <p style="width: 200px">MP4,4:3,＜24 seconds</p>
                            </div>
                        </li>
                        <div class="clearfix"></div>
                    </ul>
                    <div class="upload_hint_box">
                        <img alt="" src="../../assets/images/icon_up_g@2x.png" width="172px">
                        <h2>Drag and drop an image</h2>
                        <p>or browse to choose a file</p>
                        <div>(695x460 or larger recommended, up to 10MB each)</div>
                    </div>
                </div>
                <div class="upload_two">
                    <ul>
                        <li><img alt="" src="../../assets/images/up_m.png"></li>
                    </ul>
                </div>
            </div>

            <!--  three  -->
            <div class="three_box">
                <div class="three_item">
                    <div class="three_title">
                        <span>*</span>
                        {{$t("publish.position")}}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" type="text">
                    </div>
                </div>
                <div class="three_item">
                    <div class="three_title">
                        <span>*</span>
                        {{$t("publish.treatment")}}
                    </div>
                    <div class="input_box">
                        <input :placeholder="$t('publish.input')" oninput="value=value.replace(/[^\d.]/g,'')"
                               type="text">
                        <div class="fr">{{$t("publish.dollar")}}</div>
                    </div>
                </div>
                <div class="three_item">publishedInterview
                    <div class="three_title">
                        <span>*</span>
                        {{$t("publish.releaseMode")}}
                    </div>
                    <div class="choose_way">
                        <img @click="chooseWay = 1"
                             alt=""
                             class="cur"
                             src="../../assets/images/radio_s2@2x.png"
                             v-if="chooseWay == 1"
                             width="16"
                        >
                        <img @click="chooseWay = 1"
                             alt=""
                             class="cur"
                             src="../../assets/images/radio_n2@2x.png"
                             v-else
                             width="16"
                        >
                        <span class="choose_title">{{$t("publish.publicRelease")}}</span>
                        <span class="choose_hint">{{$t("publish.vipSketch")}}</span>
                        <img @click="peopleShow = !peopleShow"
                             alt=""
                             class="cur"
                             src="../../assets/images/radio_n@2x.png"
                             style="margin-bottom: 1px;"
                             v-if="peopleShow"
                             width="14px"
                        >
                        <img @click="peopleShow = !peopleShow"
                             alt=""
                             class="cur"
                             src="../../assets/images/radio_s@2x.png"
                             style="margin-bottom: 1px;"
                             v-else
                             width="14px"
                        >
                        <span class="choose_title_two">{{$t("publish.hide")}}</span>
                    </div>
                    <div class="choose_way">
                        <img @click="chooseWay = 2"
                             alt=""
                             class="cur"
                             src="../../assets/images/radio_s2@2x.png"
                             v-if="chooseWay == 2"
                             width="16"
                        >
                        <img @click="chooseWay = 2"
                             alt=""
                             class="cur"
                             src="../../assets/images/radio_n2@2x.png"
                             v-else
                             width="16"
                        >
                        <span class="choose_title">{{$t("publish.private")}}</span>
                        <span class="choose_hint" style="margin-left: 20px">
              <img alt="" src="../../assets/images/icon_why@2x.png" style="margin-right: 0;margin-bottom: 2px;"
                   width="13">
              {{$t("publish.whatSketch")}}
            </span>

                    </div>
                </div>
            </div>

            <!--   four   -->
            <div class="four_box">
                <div class="four_title">
                    <h2>{{ $t("publish.content") }}</h2>
                    <p>{{ $t("publish.whole") }}</p>
                </div>
                <div class="rich_box">
                    <RichText></RichText>
                </div>
            </div>
            <!--   seven   -->
            <div class="seven_box">
                <h2>{{ $t("vipRecommend.appointmentTime") }}</h2>
                <p>{{ $t("publish.fiveTime") }}</p>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker
                                :placeholder="$t('myWallet.selectADate')"
                                type="date"
                                v-model="value1">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker
                                :placeholder="$t('myWallet.selectADate')"
                                type="date"
                                v-model="value1">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker
                                :placeholder="$t('myWallet.selectADate')"
                                type="date"
                                v-model="value1">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker
                                :placeholder="$t('myWallet.selectADate')"
                                type="date"
                                v-model="value1">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
                <div class="seven_item">
                    <div class="fl">
                        <el-date-picker
                                :placeholder="$t('myWallet.selectADate')"
                                type="date"
                                v-model="value1">
                        </el-date-picker>
                    </div>
                    <img alt="" class="fr" src="../../assets/images/icon_date@2x.png" width="20px">
                </div>
            </div>
            <!--   five   -->
            <div class="five_box">
                <div class="five_item">
                    <div class="five_title">
                        <span>*</span>
                        {{ $t("publish.phone") }}
                    </div>
                    <div class="five_input">
                        <div class="fl">+ 86</div>
                        <input :placeholder="$t('publish.input')" class="fl numberInput" type="text">
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="five_item">
                    <div class="five_title">
                        <span>*</span>
                        {{ $t("publish.mailbox") }}
                    </div>
                    <div class="five_input">
                        <input :placeholder="$t('publish.input')" class="" type="text">
                    </div>
                </div>
                <div class="five_item">
                    <div class="five_title">
                        <span>*</span>
                        Whatsapp
                    </div>
                    <div class="five_input">
                        <input :placeholder="$t('publish.input')" class="" type="text">
                    </div>
                </div>
                <div class="five_item">
                    <div class="five_title">
                        {{ $t("publish.kefuliuyan") }}
                    </div>
                    <div class="five_input_text">
                        <textarea cols="30" id="" name="" rows="10"></textarea>
                    </div>
                </div>
                <div @click="isPop = true" class="save_btn cur">
                    SAVE
                </div>
            </div>
            <!--   six   -->
            <div class="six_box">
                <div class="fl six_left">
                    <h2>VIP</h2>
                    <p>{{ $t("publish.bottomStr") }}</p>
                </div>
                <div class="fr six_right">
                    Upgrade VIP
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import RichText from "@/components/richText";

    export default {
        name: "threeProjict",
        components: {
            BreadcrumbNav,
            RichText,
        },
    }
</script>

<style>
    .add_box {
        width: 1200px;
    }

    .Add_nav {
        margin-top: 31px;
        margin-bottom: 44px;
    }

    .nav_item {
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 20px;
        transition: .3s all;
    }

    .nav_active_a {
        color: #FFFFFF;
        background: #000000;
    }

    .nav_item:hover {
        color: #FFFFFF;
        background: #000000;
    }

    .upload_one {
        width: 695px;
        height: 460px;
        background: #FFFFFF;
        border: 1px dashed #E5E5E5;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .upload_one li {
        width: 33%;
        height: 120px;
        position: relative;
    }

    .upload_hint_img {
        line-height: 120px;
        padding-left: 22px;
        padding-right: 9px;
    }

    .upload_hint {
        width: 140px;
        box-sizing: border-box;
    }

    .upload_hint h2 {
        font-size: 13px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
        margin-bottom: 5px;
    }

    .upload_hint p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
    }

    .line {
        position: absolute;
        right: 0;
        top: 25px;
        display: block;
        width: 0px;
        height: 79px;
        border: 1px solid #E5E5E5;
    }

    .upload_active {
        background: #000000;
    }

    .upload_active h2, .upload_active p {
        color: #FFFFFF !important;
    }

    .upload_active .line {
        display: none !important;
    }

    .upload_hint_box {
        text-align: center;
        padding-top: 28px;
        box-sizing: border-box;
    }

    .upload_hint_box h2 {
        font-size: 27px;
        font-family: BentonSans;
        font-weight: 900;
        line-height: 32px;
        color: #000000;
        text-align: center;
        margin-top: 26px;
    }

    .upload_hint_box p {
        font-size: 17px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        margin-top: 12px;
    }

    .upload_hint_box div {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
        margin-top: 9px;
    }

    .upload_two {
        margin-top: 18px;
    }

    .upload_two li {
        width: 114px;
        height: 75px;
        background: #FFFFFF;
        border-radius: 4px;
    }

    .three_box {
        margin-top: 52px;
    }

    .three_title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 22px;
    }

    .three_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .input_box {
        width: 695px;
        height: 50px;
        line-height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 52px;
    }

    .input_box input {
        width: 80%;
        background: #FAFAFA;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }

    .input_box div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }

    .choose_way {
        line-height: 25px;
        margin-bottom: 32px;
        margin-left: 31px;
    }

    .choose_way img {
        margin-bottom: 4px;
        margin-right: 10px;
    }

    .choose_way .choose_title {
        font-size: 19px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 25px;
        color: #000000;
        padding-right: 8px;
    }

    .choose_way .choose_hint {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #949494;
        padding-right: 40px;
    }

    .choose_way .choose_title_two {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .four_box {
        margin-top: 66px;
    }

    .four_title {
        margin-left: 10px;
    }

    .four_title h2 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 14px;
    }

    .four_title p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
    }

    .rich_box {
        width: 756px;
        height: 667px;
        background: #EEEEEE;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        margin-top: 18px;
        margin-left: 10px;
    }

    .five_box {
        margin-top: 30px;
    }

    .five_item {
        margin-bottom: 31px;
    }

    .five_title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 22px;
    }

    .five_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .five_input {
        width: 536px;
        height: 50px;
        line-height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
    }

    .five_input input {
        background: #FAFAFA;
        width: 300px;
    }

    .five_input .numberInput {
        height: 22px;
        margin-top: 13px;
        background: #FAFAFA;
        margin-left: 53px;
        border-left: 1px solid #D6D6D6;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        padding-left: 45px;
    }

    .save_btn {
        width: 162px;
        height: 50px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 52px;
    }

    .six_box {
        width: 1200px;
        height: 150px;
        border-radius: 4px;
        background: url("../../assets/images/vipbg@2x.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 75px;
        margin-bottom: 30px;
    }

    .six_left {
        margin-left: 112px;
        padding-top: 43px;

    }

    .six_left h2 {
        font-size: 27px;
        font-family: BentonSans;
        font-weight: 900;
        line-height: 32px;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    .six_left p {
        font-size: 17px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
    }

    .six_right {
        width: 150px;
        height: 49px;
        border: 1px solid #949494;
        border-radius: 25px;
        box-sizing: border-box;
        line-height: 47px;
        text-align: center;
        margin-right: 209px;
        margin-top: 59px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        color: #FFFFFF;
    }

    .seven_box {
        margin-top: 60px;
    }

    .seven_box > h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .seven_box > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 7px;
        margin-bottom: 26px;
    }

    .seven_item {
        width: 537px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }

    .seven_item img {
        margin-top: 15px;
    }

    .el-input__inner {
        border: none !important;
        background: #FAFAFA !important;
    }

    .five_input_text textarea {
        width: 695px;
        height: 117px;
        background: #FAFAFA;
        opacity: 1;
        border-radius: 4px;
    }
</style>
